
import { defineComponent, computed, ref, watch, PropType } from 'vue'
import { IOrderInformation, IOrderStatusChannel, IProductInOrderDetail, ORDER_EXPEDITE } from '@/type/order'
import { ORDER_STATUS_CHANNEL, STATUS_COURIER, TYPE_SERVICE, ORDER_STATUS } from '@/constants/orderEnum'
import * as dayJs from '@/utils/dayjs'
import { useRoute, useRouter } from 'vue-router'
import { ORDER_EDIT_PATH, ORDER_PATH } from '@/constants/route'
import { LocationQueryValue } from 'vue-router'
import { ElMessageBox, ElNotification } from 'element-plus'
import { deleteOrderById, orderExpedite } from '@/api/order/order.service'
import DialogWarning from '@/components/order/DialogWarning.vue'
import { convertToDate, convertToTime, formatDefault } from '@/utils/dayjs'
import { userCurrency } from '@/use/userCurrency'
import { SHIPPING_PATH,SHIPPING_PATH_MARKETPLACE} from '@/constants/route'
import { useWindowSize } from '@/use/useWindowSize'
import { useBillPayment } from '@/use/useBillPayment'
import { paymentStore } from '@/pinia/payment/payment.pinia'
import { mapCourierLinkTracking } from '@/utils/courier'
import { useShippingStore } from '@/pinia/shipping/shipping.pinia'
import { useChat } from '@/use/useChat'
import { useFetchDataOrder } from '@/use/order/useFetchDataOrder'
import { usePrint } from '@/use/usePrint'
import PaymentReceivedForm from '@/components/order/order-detail/PaymentReceivedForm.vue'
import { useMiddleAccount } from '@/use/middleAccount'
import { COURIER } from '@/constants/courier'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useContactChannel } from '@/use/order/useContactChannel'
import { ORDER_CONTACT_CHANNEL,} from '@/constants/orderEnum'
import { useReactNativeEvent, REACT_NATIVE_EVENT } from '@/use/useReactNativeEvent'
import { VAT_TYPE } from '@/config/vat'
import DialogConfirmPaymentReceived from '@/components/order/DialogConfirmPaymentReceived.vue'
import PaymentReceivedAbbrevuatedForm from '@/components/order/order-detail/PaymentReceivedAbbrevuatedForm.vue'
import { useAnalytics } from '@/use/useAnalytics'
import { EVENT_NAME } from '@/constants/analytics'
import { useText } from '@/use/useText'
import { getProductNameWithTypes } from '@/utils/productName'

export default defineComponent({
	name: 'OrderDetailInfo',
	components: { DialogWarning, PaymentReceivedForm, DialogConfirmPaymentReceived, PaymentReceivedAbbrevuatedForm },
	props: {
		orderDetail: {
			type: Object as PropType<IOrderInformation>,
			default: () => {
				return {
				}
			}
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		const userStore = useUserStore()
		const route = useRoute()
		const router = useRouter()

		const { emitEvent } = useReactNativeEvent()
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)

		const { isChannelType, isDealerType } = useMiddleAccount()
		const { useTextOrderToParcel } = useText()
		const { toPathChat } = useChat()

		const enableInsertTaxIdOrder = computed(() => userStore.permission?.settingConfig?.enableInsertTaxIdOrder)

		const isValidSlipAmount = computed(() => {
			if (orderInformation.value.payment?.transferInfo?.amountSlipVerify) {
				return orderInformation.value.payment?.transferInfo?.amountSlipVerify >= orderInformation?.value.cost
			}
			return false
		})
		const orderID = computed<string | LocationQueryValue[]>(() => {
			if (route.query.orderID) {
				return route.query.orderID
			}
			return ''
		})
		const orderInformation = ref<IOrderInformation>({
			sender: {
				name: '',
				phoneNo: '',
				taxId: '',
			},
			customer: {
				name: '',
				phoneNo: ''
			},
			origin: {
				desc1: '',
				provinceCode: 0,
				districtCode: 0,
				subDistrictCode: 0,
				zipCode: 0,
			},
			shipment: {
				desc1: '',
				provinceCode: 0,
				districtCode: 0,
				subDistrictCode: 0,
				zipCode: 0,
				note: '',
				provinceName: '',
				districtName: '',
				subDistrictName: '',
				fee: 0,
				fullAddress:''
			},
			payment: {
				transferInfo: {
					slipImage: [],
					bankIssuerId: '',
					date: '',
					isVerifySlip:false
				},
				channel: '',
			},
			deleted: false,
			deletedAt: null,
			_id: '',
			discount: 0,
			shopId: '',
			courier: {
				_id: '',
				name: '',
				image: '',
			},
			cost: 0,
			weight: 0,
			reference: '',
			status: 'DRAFT',
			createdAt: '',
			updatedAt: '',
			products: [],
			isDeliveryFailed: false,
			channel: '',
			reasonDeliveryFailed: {
				status: '',
				reason: ''
			},
			orderInformation: '',
			note: '',
			boxSizeMeasuredFormStore: {
				weight: 0,
				width: 0,
				height: 0,
				length: 0,
			},
			boxSize: {
				width: 0,
				height: 0,
				length: 0
			},
			vatInfo:{
				vat: 0,
				vatType: VAT_TYPE.NOT_SELECT,
				isSelect: false
			},
			merchantReturnFee: 0,
			productCost: 0,
			isFillInCompleted: false
		})
		const isTransfer = computed((): boolean => {
			if(orderInformation.value?.payment?.channel === 'TRANSFER') return true
			return false
		})
		const shouldShowReason = computed(() => {
			return (
				orderInformation.value?.reasonDeliveryFailed?.reason &&
				orderInformation.value?.status === 'SENT' &&
				orderInformation.value?.isDeliveryFailed
      	)})
		const orderStatus = ref<IOrderStatusChannel>(ORDER_STATUS_CHANNEL)
		const isWarning = ref<boolean>(false)
		const txtWarning = ref<string>('กรุณาเลือกสินค้า')
		const { currencyMoneyDigits } = userCurrency()
		const shippingStore = useShippingStore()
		const { mobileMode: isMobile } = useWindowSize()
		const payment = paymentStore()
		const { onOpenDialogBillPaymentBtnClose } = useBillPayment()
		const isBlockBillPayment = computed(() => payment.isBlockBillPayment)
		const { onFetchUserData } = useFetchDataOrder()
		const assignValue = () => {
			orderInformation.value = props.orderDetail
		}
		const { channels } = useContactChannel()
		const subTxtWarning = ref<string>('')
		const { mapCourierName, isCourierBlockEditOrder } = usePrint()
		const isLoadPaymentReceived = ref<boolean>(false)
		const { logEvent } = useAnalytics()
		const isDisablePrint = computed((): boolean =>{
			return orderInformation.value.status === 'PROCESS' || orderInformation.value.status === 'PREPARE_SEND'
		})
		const isCancelPrint = computed((): boolean =>{
			return orderInformation.value.status === 'CANCEL'
		})
		const isShowDialogConfirmPaymentReceived = ref(false)
		const isPaymentReceivedAbbrevuated = ref(false)
		const isLoadPaymentAbbrevuated = ref<boolean>(false)
		watch(
			() => props.orderDetail, (value) => {
				if(value){
					orderInformation.value = value
				}
			}
		)

		const isDraft = computed(() => {
			return orderInformation.value.status === 'DRAFT'
		})

		const onConfirmWarningOrder = () => {
			subTxtWarning.value = ''
		}

		const onEditProduct = () => {
			const isBlock = isCourierBlockEditOrder(orderInformation.value?.courier?._id as string, orderInformation.value?.printLabelStatus)
			if(isBlockBillPayment.value){
				onOpenDialogBillPaymentBtnClose()
			} else if(!isDraft.value && isBlock || (!isDraft.value && orderInformation.value.courier?.name === COURIER.SPX)) {
				const textWarning = mapCourierName(orderInformation.value?.courier?._id as string)
				txtWarning.value = `ออเดอร์ "${textWarning}" ไม่สามารถแก้ไขข้อมูลได้`
				isWarning.value = true
				subTxtWarning.value = 'กรุณายกเลิกรายการ และสร้างออเดอร์ใหม่อีกครั้ง'
			} else {
				router.push({ path: ORDER_EDIT_PATH, query: {orderID: orderID.value}})
			}
		}

		const goToOrderList = () => {
			const { back: backPath } = router.options.history.state
			if (isReactNativeWebview.value) {
				// if (router.options.history.state.back === '/order') {
				emitEvent(REACT_NATIVE_EVENT.ON_CANCEL_CREATE_ORDER)
				return
				// }
			}
			const ignorePath = ['/order/create', '/order/edit']
			const isIgnorePath = ignorePath.some((path: string) => (backPath as string).includes(path))
			if (isIgnorePath) {
				router.push({
					path: ORDER_PATH,
					query: { statusOrder: orderInformation.value.status }
				})
				return
			}
			router.push(`${backPath}`)
		}

		const isHideBtnDeleteOrder = computed((): boolean => {	
			if (!orderInformation.value._id) {
				return false
			}		
			return orderInformation.value.status === 'DRAFT' || orderInformation.value.status === 'PREPARE_SEND' || orderInformation.value.status === 'PROCESS'
		})

		const isBtnClaimOrder = computed((): boolean => {
			return orderInformation.value.status === ORDER_STATUS.SENT || orderInformation.value.status === ORDER_STATUS.COMPLETED || orderInformation.value.status === ORDER_STATUS.REJECT
		})

		const total = computed((): number => {
			let orderTotalPrice = Number(orderInformation.value.price)
			if(orderInformation.value.vatInfo?.vatType === VAT_TYPE.INCLUDE_VAT){
				orderTotalPrice = Number(orderInformation.value.price) - Number(orderInformation.value.vatInfo.vat)
			}
			return orderTotalPrice
		})

		const onDeleteOrder = async() => {
			try {
				const res = await deleteOrderById(orderID.value)
				if(res.updateStatus === 'SUCCESSFUL'){
					router.push({ path: ORDER_PATH, query: {statusOrder: orderInformation.value.status}})
					await onFetchUserData()
					ElNotification({
						title: 'สำเร็จ',
						message: 'ลบออเดอร์สำเร็จ',
						type: 'success',
						showClose: !isReactNativeWebview.value
					})
					// emit to react native webview then they can refetch new data
					// on application
					if (window.ReactNativeWebView) {
						emitEvent(REACT_NATIVE_EVENT.ON_DELETE_ORDER_SUCCESS)
					}
				}
			} catch (_) {
				ElNotification({
					title: 'เกิดข้อผิดพลาด',
					message: 'ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			}
		}

		const onConfirmDeleteOrder = () => {
			subTxtWarning.value = ''
			isWarning.value = true
			txtWarning.value = `คุณต้องการลบออเดอร์ "${orderInformation.value.reference}"`
		}

		const isBtnSuccess = computed(() => {
			return isWarning.value && subTxtWarning.value !== ''
		})

		const imageProduct = (item: any) => {
			return item.product?.images?.[0] ? item.product?.images[0] : item?.images?.[0] ? item?.images[0] : require('@/assets/images/other/not-found-image.png')
		}

		const productName = (item: any) => {
			if (item.product) {
				let nameMarketplace = null
				if (item.product?.marketplaces) {
					 const marketplaceInfo = item.product?.marketplaces?.find((marketplce:{marketplaceType:string}) => marketplce?.marketplaceType === orderInformation.value.channel)
					 if (marketplaceInfo) {
						nameMarketplace = marketplaceInfo
					 }
				}
				return nameMarketplace ? nameMarketplace.name : item.product?.name
					? item.product?.name 
					: item.product.parent?.name
						? item.product.parent?.name 
						: '-'
			}else{
				return item?.name || '-'
			}
		}
		const getChannelIcon = computed(() => {
			return (channelKey: string) => {
				const matchingChannel = channels.value.find((channel: { key: string }) => channel.key === channelKey)
				return matchingChannel ? matchingChannel.icon : null
			}
		})
		
		const onPrintLabel = () => {
			if(isBlockBillPayment.value){
				onOpenDialogBillPaymentBtnClose()
			} else {
				const orderIDArray: string[] = []
				orderIDArray.push(orderInformation.value._id)
				shippingStore.updateOrderListId(orderIDArray)
				if (orderInformation.value.marketplaceOrder) {
					return router.push({ path: SHIPPING_PATH_MARKETPLACE, query:{ marketplaceType:orderInformation.value.channel} })
				}
				router.push({ path: SHIPPING_PATH })
			}
		}

		const isTooltipPrint = computed(() => {
			return orderInformation.value.status === 'DRAFT'
		})

		const hurryOrder = async () => {
			//
			const isConfirm = await ElMessageBox.confirm(
				'ยืนยันเร่งนำส่งพัสดุ',
				{
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'warning',
					showClose: !isReactNativeWebview.value
				}
			)
			if (!isConfirm) return

			await orderExpedite(orderInformation.value._id)

			// change status to complete expedite
			if (orderInformation.value?.condition) {
				orderInformation.value.condition.expediteStatus = ORDER_EXPEDITE.COMPLETED
			}

			ElNotification({
				title: 'สำเร็จ',
				message: 'แจ้งเร่งพัสดุกับขนส่งสำเร็จ',
				type: 'success',
				showClose: !isReactNativeWebview.value
			})
		}

		const onConfirmPaymentReceived = ( Type :string ) =>{
			if(Type == 'Abbreviated'){
				isPaymentReceivedAbbrevuated.value = true
			}else{
				isPaymentReceivedAbbrevuated.value = false
			}
			isShowDialogConfirmPaymentReceived.value = true
		}

		const onDownloadPaymentReceived = () => {
			shippingStore.updateOrderListId([orderInformation.value._id])
			if (isReactNativeWebview.value) {
				return router.push({
					path: '/order/order-receipt-payment',
					query: { redirectPath: `/order/detail?orderID=${orderInformation.value._id}` }
				})
			}
			isLoadPaymentReceived.value = true
			if(!isPaymentReceivedAbbrevuated.value){
				isLoadPaymentAbbrevuated.value = false
				logEvent({ eventName: EVENT_NAME.clickPrintReceipt })
			}else{
				isLoadPaymentAbbrevuated.value = true
				isLoadPaymentReceived.value = false
				logEvent({ eventName: EVENT_NAME.clickPrintReceiptAbbreviated })
			}
			//isLoadPaymentReceived.value = true
		}

		const onHandleTrackingLinkClick = (event: Event, orderInformation: any) => {
			// for handle react native webview only
			if (isReactNativeWebview.value) {
				event.preventDefault()

				// emit onClick
				const emitData = {
					orderInformation,
					link: mapCourierLinkTracking(orderInformation.courier.name, orderInformation.trackingNumber)
				}

				emitEvent(REACT_NATIVE_EVENT.ON_OPEN_TRACKING_LINK, emitData)
			}
		}

		const typeProduct = (item:IProductInOrderDetail) => {
			if (item.product?.marketplaces) {
				const typeMarketplace = item.product?.marketplaces?.find((marketplce:{marketplaceType:string}) => marketplce?.marketplaceType === orderInformation.value.channel)
				if (typeMarketplace) {
					return typeMarketplace?.types.join('-')
				}
			}
			else if (item.product && item.product.types && item.product.types.length > 0) {
				return item.product.types.join('-')
			} else if (item.product && item.product.marketplaces && item.product.marketplaces[0] && item.product.marketplaces[0].types) {
				return item.product.marketplaces[0].types.join('-')
			} else {
				return ''
			}
		}
		return {
			orderInformation,
			isTransfer,
			orderStatus,
			dayJs,
			isWarning,
			getChannelIcon,
			txtWarning,
			isHideBtnDeleteOrder,
			STATUS_COURIER,
			isDisablePrint,
			isCancelPrint,
			isMobile,
			isBlockBillPayment,
			subTxtWarning,
			isBtnSuccess,
			isTooltipPrint,
			isBtnClaimOrder,
			ORDER_EXPEDITE,
			isLoadPaymentReceived,
			shouldShowReason,
			ORDER_STATUS,
			toPathChat,
			onOpenDialogBillPaymentBtnClose,
			onConfirmDeleteOrder,
			onEditProduct,
			assignValue,
			goToOrderList,
			onDeleteOrder,
			convertToDate,
			convertToTime,
			currencyMoneyDigits,
			productName,
			getProductNameWithTypes,
			onPrintLabel,
			mapCourierLinkTracking,
			onConfirmWarningOrder,
			hurryOrder,
			formatDefault,
			onDownloadPaymentReceived,
			isChannelType,
			COURIER,
			isValidSlipAmount,
			enableInsertTaxIdOrder,
			TYPE_SERVICE,
			ORDER_CONTACT_CHANNEL,
			isReactNativeWebview,
			onHandleTrackingLinkClick,
			isDealerType,
			total,
			useTextOrderToParcel,
			onConfirmPaymentReceived,
			isShowDialogConfirmPaymentReceived,
			isLoadPaymentAbbrevuated,
			typeProduct,
			imageProduct
		}
	}
})
