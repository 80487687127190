
import { paymentStore } from '@/pinia/payment/payment.pinia'
export const useBillPayment = () => {
	const payment = paymentStore()

	const onOpenDialogBillPaymentBtnClose = () => {
		payment.dialogBillPayment.isShow = true
		payment.dialogBillPayment.isBtnClose = true
	}

	const onOpenDialogBillPayment = () => {
		payment.dialogBillPayment.isShow = true
		payment.dialogBillPayment.isBtnClose = false
	}

	const onCloseDialogBillPayment = () => {
		payment.dialogBillPayment.isShow = false
	}

	return {
		onOpenDialogBillPayment,
		onOpenDialogBillPaymentBtnClose,
		onCloseDialogBillPayment,
	}
}