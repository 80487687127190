
import { defineComponent, onMounted, computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { LocationQueryValue } from 'vue-router'
import OrderDetailInfo from '@/components/order/order-detail/OrderDetailInfo.vue'
import { getOrderDetail } from '@/api/order/order.service'
import { IOrderInformation } from '@/type/order'
import { ElNotification } from 'element-plus'
import DialogConfirmChangeStatus from '@/components/order/DialogConfirmChangestatus.vue'
import DialogWarning from '@/components/order/DialogWarning.vue'
import DialogWarningChangeStatus from '@/components/order/DialogWarningChangeStatus.vue'
import { useUserProfile } from '@/use/useUserProfile'
import { useBillPayment } from '@/use/useBillPayment'
import { paymentStore } from '@/pinia/payment/payment.pinia'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { ORDER_CREATE_PATH } from '@/constants/route'
import { useWindowSize } from '@/use/useWindowSize'
import { useClipboardOrder } from '@/use/order/useClipboard'
import { useAnalytics } from '@/use/useAnalytics'
import { EVENT_NAME } from '@/constants/analytics'
import { useMiddleAccount } from '@/use/middleAccount'
import { useText } from '@/use/useText'
import { getShopSetting } from '@/api/shop.api'
import { useUserStore } from '@/pinia/user/user.pinia'

export default defineComponent({
	name: 'OrderDetail',
	components: { OrderDetailInfo, DialogConfirmChangeStatus, DialogWarning, DialogWarningChangeStatus },
	setup() {
		const route = useRoute()
		const router = useRouter()
		const { mobileMode: isMobile } = useWindowSize()
		const { generateReplyClipboardMessage } = useClipboardOrder()
		const { isDealerType } = useMiddleAccount()
		const { useTextOrderToParcel } = useText()
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const userStore = useUserStore()
		const fillIn = computed(() => userStore.permission.fillInConfigMessage)
		const orderID = computed<string | LocationQueryValue[]>(() => {
			if (route.query.orderID) {
				return route.query.orderID
			}
			return ''
		})
		const isLoading = ref<boolean>(false)
		const orderDetail = ref<IOrderInformation>({
			sender: {
				name: '',
				phoneNo: '',
				taxId: '',
			},
			customer: {
				name: '',
				phoneNo: ''
			},
			origin: {
				desc1: '',
				provinceCode: 0,
				districtCode: 0,
				subDistrictCode: 0,
				zipCode: 0,
			},
			shipment: {
				desc1: '',
				provinceCode: 0,
				districtCode: 0,
				subDistrictCode: 0,
				zipCode: 0,
				note: '',
				provinceName: '',
				districtName: '',
				subDistrictName: '',
				fee: 0
			},
			payment: {
				transferInfo: {
					slipImage: [],
					bankIssuerId: '',
					date: '',
					isVerifySlip:false
				},
				channel: '',
			},
			deleted: false,
			deletedAt: null,
			_id: '',
			discount: 0,
			shopId: '',
			courier: {
				_id: '',
				name: '',
				image: '',
			},
			cost: 0,
			weight: 0,
			reference: '',
			status: '',
			createdAt: '',
			updatedAt: '',
			products: [],
			condition: {
				isFillInCustomerInfo: false,
				isFillInPayment: false,
				isFillInShipmentAddress: false,
			},
			boxSize: {
				width: 0,
				height: 0,
				length: 0
			},
			merchantReturnFee: 0,
			productCost: 0,
			printLabelStatus: 'PENDING',
		})
		const OrderDetailInfoRef = ref<any>(null)
		const isChangeStatusOrder = ref<boolean>(false)
		const shopStore = useShopStore()
		const currentShop = computed(() => shopStore.currentShop)
		const isWarning = ref<boolean>(false)
		const txtWarning = ref<string>('')
		const isKyc = ref<boolean>(false)
		const orderSelectedById = ref<string[]>([])
		const isWarningChangeStatusOrder = ref<boolean>(false)
		const orderSelectedNotPrint = ref<{reference: string, message: string}[]>([])
		useUserProfile()
		const payment = paymentStore()
		const { onOpenDialogBillPaymentBtnClose } = useBillPayment()
		const isBlockBillPayment = computed(() => payment.isBlockBillPayment)
		const { logEvent } = useAnalytics()

		const onGetOrderDetail = async() => {
			try {
				if (orderID.value) {
					isLoading.value = true
					const res = await getOrderDetail(orderID.value)
					orderDetail.value = res
				}
			} catch (_) {
				isLoading.value = false
				ElNotification({
					title: 'ผิดพลาด',
					message: 'ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			} finally {
				isLoading.value = false
			}
		}

		const onCopySuccess = () => {
			ElNotification({
				title: 'สำเร็จ',
				message: 'คัดลอกลิงก์สรุปยอด สำเร็จ',
				type: 'success',
				showClose: !isReactNativeWebview.value
			})

			// logs
			logEvent({ eventName: EVENT_NAME.copyFillinLink })
		}

		const onChangeStatusOrder = () => {
			if(isBlockBillPayment.value){
				onOpenDialogBillPaymentBtnClose()
			} else {
				orderSelectedNotPrint.value = []
				orderSelectedById.value = []
				const isChangeStatus = orderDetail.value.status === 'DRAFT' || orderDetail.value.status === 'PREPARE_SEND' || orderDetail.value.status === 'PROCESS'
				const isChangeStatusOrderComplete = orderDetail.value.condition?.isFillInCustomerInfo && orderDetail.value.condition?.isFillInPayment && orderDetail.value.condition?.isFillInShipmentAddress
				if(isChangeStatus){
					if(!isChangeStatusOrderComplete && !orderDetail.value.marketplaceOrder){
						let textWarningOrder = ''
						if(!orderDetail.value.condition?.isFillInSenderInfo && isDealerType.value) {
							textWarningOrder += '<p>- ชื่อหรือเบอร์โทรผู้ส่ง</p>'
						}
						if(!orderDetail.value.condition?.isFillInCustomerInfo) {
							textWarningOrder += isDealerType.value ? '<p>- ชื่อหรือเบอร์โทรผู้รับ</p>' : '<p>- ชื่อหรือเบอร์โทรลูกค้า</p>'
						}
						if(!orderDetail.value.condition?.isFillInPayment) {
							textWarningOrder += '<p>- ข้อมูลการชำระเงิน</p>'
						}
						if(!orderDetail.value.condition?.isFillInOriginAddress && isDealerType.value) {
							textWarningOrder += '<p>- ที่อยู่ในการจัดส่งขอผู้ส่ง</p>'
						}
						if(!orderDetail.value.condition?.isFillInShipmentAddress) {
							textWarningOrder += isDealerType.value ? '<p>- ที่อยู่ในการจัดส่งของผู้รับ</p>' : '<p>- ที่อยู่ในการจัดส่งของลูกค้า</p>'
						}
						ElNotification({
							title: 'แจ้งเตือน',
							dangerouslyUseHTMLString: true,
							message: 'กรุณาแก้ไขข้อมูล ' + textWarningOrder,
							type: 'warning',
							showClose: !isReactNativeWebview.value
						})
					}else if(orderDetail.value.printLabelStatus === 'PENDING' && orderDetail.value.status === 'PREPARE_SEND' ) {
						isWarningChangeStatusOrder.value = true
						orderSelectedNotPrint.value.push({
							reference: orderDetail.value.reference as string,
							message: 'กรุณาทำการพิมพ์ใบปะหน้า',
						})
					}else {
						isChangeStatusOrder.value = true
						orderSelectedById.value.push(orderDetail.value._id)
					}
				} else {
					ElNotification({
						title: 'แจ้งเตือน',
						message: 'กรุณาเลือกรายการที่ต้องการ ย้ายสถานะ',
						type: 'warning',
						showClose: !isReactNativeWebview.value
					})
				}
			}
		}

		const onWarningVerifyKYC = () => {
			isWarning.value = true
			txtWarning.value = 'กรุณายืนยันตัวตน เพื่อรับสิทธิ์พิเศษมากมาย'
			isKyc.value = true
		}

		const updateStatusSuccess = async() => {
			await onGetOrderDetail()
		}

		const isHideBtnStatus = computed((): boolean =>{
			return orderDetail.value.status === 'DRAFT' || orderDetail.value.status === 'PROCESS'
		})

		const toCreateOrder = () => {
			router.push({ path: ORDER_CREATE_PATH })
		}

		onMounted(async () => {
			if(orderID.value){
				await onGetOrderDetail()
			}
		})

		// watch route query change then refetch again
		watch(
			() => route.query.orderID,
			() => {
				onGetOrderDetail()
			}
		)
		return {
			orderDetail,
			OrderDetailInfoRef,
			isChangeStatusOrder,
			currentShop,
			orderSelectedById,
			isWarning,
			txtWarning,
			isKyc,
			isWarningChangeStatusOrder,
			orderSelectedNotPrint,
			isHideBtnStatus,
			isBlockBillPayment,
			isMobile,
			isDealerType,
			onCopySuccess,
			onChangeStatusOrder,
			onWarningVerifyKYC,
			updateStatusSuccess,
			onOpenDialogBillPaymentBtnClose,
			toCreateOrder,
			generateReplyClipboardMessage,
			isLoading,
			useTextOrderToParcel,
			fillIn
		}
	},
})
