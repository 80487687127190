
import { defineComponent, ref, computed, PropType, watch } from 'vue'
import { ORDER_STATUS, ORDER_STATUS_CHANNEL } from '@/constants/orderEnum'
import { updateStatusOrder } from '@/api/order/order.service'
import { useWindowSize } from '@/use/useWindowSize'
import { ElNotification } from 'element-plus'
import DialogWarningStatusOrder from '@/components/order/DialogWarningStatusOrder.vue'
import DialogWarningStatusOrderUnNotify from '@/components/order/DialogWarningStatusOrderUnNotify.vue'
import DialogWarningLimitOrder from '@/components/order/DialogWarningLimitOrder.vue'
import DialogSelectServiceType from '@/components/order/dialog-order/DialogSelectServiceType.vue'
import TopUpDialogWarning from '@/components/top-up/TopUpDialogWarning.vue'
import { IBodyChangeStatusOrder } from '@/api/order/type'
import { CONSTANTS, IOrderStatusChannel } from '@/type/order'
import { useFetchDataOrder } from '@/use/order/useFetchDataOrder'
import { getSummaryOrderCost } from '@/api/order/order.service'
import { useCredit } from '@/use/credits/useCredit'
import { useGlobalStore } from '@/pinia/global.pinia'
import { useReactNativeEvent, REACT_NATIVE_EVENT } from '@/use/useReactNativeEvent'


export interface IOrderPaymentType {
	jntServiceType?: {
		serviceType:string
	};
	njvServiceType?: {
		serviceType:string
	};
	spxServiceType?: {
		serviceType:string,
		pickupTime:number
		slot:{
			pickupTimeRange:string;
			pickupTimeRangeId:number;
		}
	};
	tiktokServiceType?: {
		serviceType:string,
		pickupTime:number
		slot:{
			pickupTimeRange:string;
			pickupTimeRangeId:number;
		}
	};
	shopeeServiceType:any

}
export default defineComponent({
	name: 'DialogConfirmChangestatus',
	components: {
		DialogWarningStatusOrder,
		DialogWarningStatusOrderUnNotify,
		DialogWarningLimitOrder,
		DialogSelectServiceType,
		TopUpDialogWarning,
	},
	props: {
		isChangeStatusOrder: {
			type: Boolean,
			default: false,
		},
		oldStatus: {
			type: String,
			default: '',
		},
		orderSelectedById: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		shopId: {
			type: String,
			default: '',
		},
		orderSelectedCourier: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
	},
	emits: ['update:isChangeStatusOrder', 'changeStatusSuccess', 'verifyKYC', 'changeStatusError'],
	setup(props, { emit }) {

		const globalStore = useGlobalStore()
		const { isTopUpType } = useCredit()
		const orderStatusChannel = ref<IOrderStatusChannel>(ORDER_STATUS_CHANNEL)
		const isLoadingChangeStatus = ref<boolean>(false)
		const { mobileMode: isMobile } = useWindowSize()
		const isWarningStatusOrder = ref<boolean>(false)
		const isWarningStatusOrderUnNotify = ref<boolean>(false)
		const orderErrorList = ref([])
		const orderUnNotifyList = ref([])
		const orderFailedStatusList = ref([])
		const orderResDesc = ref<string>('')
		const onCloseDialog = () => {
			isLoadingChangeStatus.value = false
			emit('update:isChangeStatusOrder', false)
		}
		const isWarningLimitOrder = ref<boolean>(false)
		const isWarningPickupOrder = ref<boolean>(false)
		const isWarningCredit = ref<boolean>(false)
		const warningCourierName = ref<string>('')
		const isValidServiceTypeList = ref<any[]>([])

		const { onFetchUserData } = useFetchDataOrder()
		const { emitEvent } = useReactNativeEvent()
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		
		enum ORDER_SUMMARY {
			SKIP = 'SKIP',
			DISPLAY = 'DISPLAY',
			}
		const newStatus = computed(() => {
			switch (props.oldStatus) {
			case 'DRAFT':
				return 'PROCESS'
			case 'PROCESS':
				return 'PREPARE_SEND'
			case 'REJECT':
				return 'PROCESS'
			default:
				return 'PROCESS'
			}
		})
		const listOrder = ref(0)
		const orderStatusTransferSuccess = ref(0)
		const isFetchingSummary = ref(false)
		const totalFee = ref(0)
		const shipmentFee = ref(0)
		const remoteFee = ref(0)
		const codFee = ref(0)
		const smsFee = ref(0)
		const statusSummary = ref(ORDER_SUMMARY.SKIP)

		const numberFormat = (number: number): string => number?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0'

		const isDisplayCostSummary = computed(() => {
			if (props.oldStatus === ORDER_STATUS.DRAFT) {
				if((statusSummary.value === ORDER_SUMMARY.DISPLAY || isTopUpType.value)){
					return true
				}
			}
			return false
		})

		watch(
			() => props.isChangeStatusOrder,
			async () => {
				try {
					isFetchingSummary.value = true
					const response = await getSummaryOrderCost(props.orderSelectedById, props.shopId)
					statusSummary.value = response.statusSummary
					if (props.isChangeStatusOrder && isDisplayCostSummary.value) {
						//
						totalFee.value = response.totalFee
						shipmentFee.value = response.shipmentFee
						remoteFee.value = response.remoteFee
						codFee.value = response.codFee
						smsFee.value = response.smsFee
					}
				} catch (error) {
					//
				} finally {
					isFetchingSummary.value = false
				}
			}
		)

		const onConfirmChangeStatus = async (serviceInfo: IOrderPaymentType | null) => {
			try {
				isLoadingChangeStatus.value = true
				let params: IBodyChangeStatusOrder = {
					shopId: props.shopId,
					orders: props.orderSelectedById,
					status: newStatus.value,
				}
				if (serviceInfo?.jntServiceType) {
					params.jntServiceType = serviceInfo.jntServiceType.serviceType
				}
				if(serviceInfo?.njvServiceType) {
					params.njvServiceType = serviceInfo.njvServiceType.serviceType
				}
				if(serviceInfo?.tiktokServiceType) {
					params.tiktokServiceType = serviceInfo.tiktokServiceType.serviceType
				}
				if(serviceInfo?.spxServiceType) {
					const spxServiceType = serviceInfo.spxServiceType.serviceType
					if (serviceInfo.spxServiceType.pickupTime) {
						params = {
							...params,
							pickupInfo:{
								pickupTime:serviceInfo.spxServiceType.pickupTime,
								pickupTimeRange:serviceInfo.spxServiceType.slot.pickupTimeRange,
								pickupTimeRangeId:serviceInfo.spxServiceType.slot.pickupTimeRangeId
							}
						}
					}
					params = {
						...params,
						spxServiceType
					}
				}
				if(serviceInfo?.shopeeServiceType) {
					let shopeeServiceType = serviceInfo.shopeeServiceType.serviceType
					params = {
						...params,
						shopeeServiceType
					}
				}
				const res = await updateStatusOrder(params)
				if (res.updateStatus) {
					await onFetchUserData()
					onCloseDialog()
					ElNotification({
						title: 'สำเร็จ',
						message: `เปลี่ยนสถานะสำเร็จ ${props.orderSelectedById.length} ออเดอร์`,
						type: 'success',
						showClose: !isReactNativeWebview.value
					})
					emit('changeStatusSuccess', newStatus.value)

					// react native Emit event
					if (window.ReactNativeWebView) {
						emitEvent(REACT_NATIVE_EVENT.ON_SUCCESS_CHANGE_ORDER_STATUS)
					}
					
				}
				isWarningPickupOrder.value = false
			} catch (err: any) {
				isLoadingChangeStatus.value = false
				isWarningPickupOrder.value = false
				const isFocusStatus =
                    props.oldStatus === 'PREPARE_SEND' ||
                    props.oldStatus === 'PROCESS' ||
                    props.oldStatus === 'DRAFT'

				if(props.oldStatus === ORDER_STATUS.DRAFT && err.response.data.resDesc === CONSTANTS.UNABLE_NOTIFY_MESSAGES){
					isWarningStatusOrderUnNotify.value = true
					listOrder.value = err?.response?.data?.payload?.orderTotal
					orderStatusTransferSuccess.value = err?.response?.data?.payload?.orderSuccessTotal
					orderFailedStatusList.value = err?.response?.data?.payload?.listOrderError || []
					orderResDesc.value = err?.response?.data?.payload?.resDesc || CONSTANTS.UNABLE_NOTIFY_MESSAGES
					orderUnNotifyList.value = err?.response?.data?.payload?.listOrderTrackingNotifyFail || []
					await onFetchUserData()
					onCloseDialog()
				}
				else if (
					isFocusStatus &&
                    err.response.data.resDesc === 'ORDER_ERROR_INTEGRATE_WITH_COURIER'
				) {
					isWarningStatusOrder.value = true
					orderErrorList.value = err?.response?.data?.payload?.listOrderError || []
					onCloseDialog()
				} else {
					isValidServiceTypeList.value = []
					switch (err.response.data.resDesc) {
					case 'SHOP_IS_NOT_KYC':
						emit('verifyKYC')
						break
					case 'ORDER_TRANSFER_LIMIT':
						isWarningLimitOrder.value = true
						break
					case 'ORDER_SERVICE_TYPE_NOT_FOUND':
						isValidServiceTypeList.value = err.response.data.list
						isWarningPickupOrder.value = true
						break
					case 'CREDIT_TOP_UP_NOT_ENOUGH':
						isWarningCredit.value = true
						break
					default:
						if(!globalStore.subscription.isShowBlockDialog){
							ElNotification({
								title: 'ผิดพลาด',
								message: 'กรุณาติดต่อ Support',
								type: 'error',
								showClose: !isReactNativeWebview.value
							})
						}
						break
					}
					onCloseDialog()
				}
				if(orderStatusTransferSuccess.value > 0 && orderFailedStatusList.value.length == 0){
					emit('changeStatusSuccess',newStatus.value)
				}else{
					emit('changeStatusError')
				}
			
			}
		}

		return {
			orderStatusChannel,
			newStatus,
			isLoadingChangeStatus,
			isMobile,
			isWarningStatusOrder,
			isWarningStatusOrderUnNotify,
			orderErrorList,
			orderUnNotifyList,
			listOrder,
			orderStatusTransferSuccess,
			orderFailedStatusList,
			isWarningLimitOrder,
			isWarningPickupOrder,
			isWarningCredit,
			warningCourierName,
			isValidServiceTypeList,
			onCloseDialog,
			onConfirmChangeStatus,
			isFetchingSummary,
			totalFee,
			codFee,
			shipmentFee,
			remoteFee,
			smsFee,
			numberFormat,
			isTopUpType,
			isDisplayCostSummary,
			orderResDesc,
		}
	},
})
