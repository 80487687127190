import { ORDER_PAYMENT_CHANNEL } from '@/constants/orderEnum'
import { IOrderDetail } from './type'
import { COURIER_NAME_ID } from '@/constants/shippingEnum'

export const useClipboardOrder = () => {
	const handleProductName = (item: any) => {
		return item?.name ? item?.name : item?.parent?.name + `(${item?.types?.join(',')})`
	}

	const generateReplyClipboardMessage = (order:{orderDetail: IOrderDetail,fillIn:string}) => {
		const { orderDetail, fillIn } = order
		if (!orderDetail?.courier?._id) {
			// force reload if courier map not found
			// cause new version object will change
			// to prevent customer copy link without courier
			return ''
		}
		let text = fillIn	
		text = text.replaceAll('{{reference}}', orderDetail?.reference || '-')
		text = text.replaceAll('{{courier}}', mapCourier(orderDetail?.courier?._id) || '-')
		text = text.replaceAll('{{trackingNumber}}', orderDetail?.trackingNumber || '-')
        
		if (orderDetail.products?.length) {
			//
			let productText = `จำนวนทั้งหมด ${orderDetail.products?.length} รายการ \n`
			for (const product of orderDetail.products) {
				productText += `${product?.productSet?.length ? '[เซ็ต] ' : '' }${handleProductName(product.product)} x${product.unit} ${product.price?.toLocaleString()}.- \n`
			}

			text = text.replaceAll('{{productList}}', productText)
		} else {
			// remove productList
			text = text.replaceAll('{{productList}}', '')
		}
		const paymentChannel = orderDetail.payment.channel === ORDER_PAYMENT_CHANNEL.TRANSFER ? 'โอนเงิน' : 'เก็บเงินปลายทาง'
		text = text.replaceAll('{{costInfo}}', `👉🏻 ราคารวม ${orderDetail.cost?.toLocaleString()}.-`)
		text = text.replaceAll('{{customerName}}', `ชื่อผู้รับ: ${orderDetail.customer?.name || '-'}`)
		text = text.replaceAll('{{customerAddress}}', `ที่อยู่ในการจัดส่ง: ${orderDetail.shipment?.desc1 || '-'} ต.${orderDetail.shipment?.subDistrictName || '-'} อ.${orderDetail.shipment?.districtName || '-'} จ.${orderDetail.shipment?.provinceName || '-'} ${orderDetail.shipment?.zipCode || '-'}`)
		text = text.replaceAll('{{customerPhoneNo}}', `เบอร์ติดต่อ: ${orderDetail.customer?.phoneNo || '-'}`)
		text = text.replaceAll('{{paymentChannel}}', `ช่องทางการชำระ: ${paymentChannel}`)
		text = text.replaceAll('{{link}}', `${window.location.protocol}//${window.location.host}/fill/order?reference=${orderDetail?.reference}`)
		
		return text
	}

	const mapCourier = (courierId: string) => {
		if (courierId === COURIER_NAME_ID.FLASH_BULKY) {
			return 'Flash Bulky'
		  }
		if (courierId === COURIER_NAME_ID.FLASH) {
		  return 'Flash'
		}
		if (courierId === COURIER_NAME_ID.THAIPOST) {
		  return 'ไปรษณีย์ไทย'
		}
		if (courierId === COURIER_NAME_ID.JNT) {
		  return 'J&T Express'
		}
		if (courierId === COURIER_NAME_ID.SPX) {
			return 'Shopee Express'
		}
		if (courierId === COURIER_NAME_ID.KERRY) {
			return 'Kerry'
		}
		if (courierId === COURIER_NAME_ID.NINJA_VAN) {
			return 'Ninja Van'
		}
		return ''
	  }

	return {
		generateReplyClipboardMessage
	}
}