import { IProductListRes } from '@/api/products/type'
import { VAT_TYPE } from '@/config/vat'
import { IOrigin, IContactChannel } from '@/api/order/type'

export interface IPaymentChannel {
	value: string
	label: string
	disabled?: boolean
}
export interface ICourier {
	value: string
	name: string
	image?: any
}
export interface ILabelStatus {
	value: string
	label: string
	disabled?: string
}
export interface ITransferInfo {
	slipImage: string | null
	paymentDateTime: string
	amount?: number
	imageSrc: ''
	bankImg?: string
	bankName?: string
	paymentTime?: string
}

export interface IShipment {
	desc1: string
	provinceCode: string | number | null
	districtCode: string | number | null
	subDistrictCode: string | number | null
	zipCode: string | number | null
	note: string
	fee: number
	remoteFee: number
	islandFee?: number
}

export interface IBankList {
	code: string
	image: string
	name: string
	_id: string
}

export interface IOrderDetail {
	sender: {
		name: string
		phoneNo: string
		taxId?: string
	}
	orderId: string,
	customer: {
		name: string
		phoneNo: string
		taxId?: string
	}
	products: Array<IProductListRes>
	shipment: IShipment
	payment: {
		channel: string
		transferInfo: {
			slipImage: any
			paymentDateTime?: string
			amount?: number
			isVerifySlip: boolean
		}
		courier: string
		cost: number
		discount: number
		productPrice: number
		vatInfo: {
			vat: number
			vatType: VAT_TYPE
			isSelect: boolean
		}
		
	}
	weight?: number
	note?: string
	isBoxSize: boolean
	boxSizeMeasuredFormStore?: {
		weight: number
		width: number
		height: number
		length: number
	}
	origin?: IOrigin | null,
	contactChannels?: IContactChannel,
	channel?: string, 
	pageId?: string,
}

export interface IProductInOrder {
	price: number
	product: string
	unit: number
	_id: string
}
export interface IOrderSelection {
	createdAt: string
	customer: {
		name: string
		phoneNo: string
	}
	deleted: boolean
	deletedAt: null
	discount: number
	products: Array<IProductInOrder>
	shipment: {
		desc1: string
		provinceCode: string | number | null
		districtCode: string | number | null
		subDistrictCode: string | number | null
		zipCode: string | number | null
		note: string
		fee: number
	}
	payment: {
		channel: string
		transferInfo?: {
			slipImage?: string
			bankIssuerId?: string
			date?: string
		}
	}
	shopId: string | number | undefined
	status: string
	updatedAt: string
	_id: string
	condition?: {
		isFillInCustomerInfo: boolean
		isFillInPayment: boolean
		isFillInShipmentAddress: boolean
	}
	courier?: {
		image: string
		name: string
		_id: string
	}
	reference?: string
	printLabelStatus?: string
	channel?:string
	marketplaceOrder?:{
		marketplaceType:string
	}
}
export interface IStatusOrderList {
	id: number
	label: string
	value: string
	count?: number
}

export interface IOrderErrorList {
	reference: string
	message: string
}
export interface IOrderUnNotifyList {
	reference: string
	trackingNumber: string
	message: string
	pageId: string
	name: string
}
export interface IBankObj {
	name: string
	code: string
	image: string
}
export interface IOrderInfo {
	name: string
	phoneNumber: string
	taxId?: string
	courier: string
	shipment: {
		desc1?: string
		provinceCode?: number | null
		districtCode?: number | null
		subDistrictCode?: number | null
		zipCode?: number | null
		fee?: number
	}
}
export interface IStatusCourier {
	COD: string
	TRANSFER: string
}
export interface IOboStatusOrder {
	text: string
	color: string
}
export interface IOrderStatusChannel {
	DRAFT: IOboStatusOrder
	PROCESS: IOboStatusOrder
	PREPARE_SEND: IOboStatusOrder
	SENT: IOboStatusOrder
	COMPLETED: IOboStatusOrder
	REJECT: IOboStatusOrder
	CANCEL: IOboStatusOrder
}
export interface IProductInOrderDetail {
	_id: string
	product: {
		images: string[]
		_id: string
		name: string
		sku: string
		weight: number
		types?: string[]
		productSet: any[]
		marketplaces: any[]
	}
	unit: number
	price: number
}
export interface IOrderInformation {
	sender: {
		name: string
		phoneNo: string
		taxId?: string
	},
	customer?: {
		name: string
		phoneNo: string
		taxId?: string
	}
	origin?: {
		desc1: string
		provinceCode: number
		districtCode: number
		subDistrictCode: number
		zipCode: number
		provinceName?: string
		districtName?: string
		subDistrictName?: string
	}
	shipment?: {
		desc1: string
		provinceCode: number
		districtCode: number
		subDistrictCode: number
		zipCode: number
		note: string
		provinceName: string
		districtName: string
		subDistrictName: string
		fee: number
		remoteFee?: number
		fullAddress?:string
	}
	payment?: {
		transferInfo: {
			slipImage: { url: string; isValid: boolean; rawData: boolean }[]
			bankIssuerId: string
			date: string
			isVerifySlip: boolean
			amountSlipVerify?: number
		}
		channel: string
	}
	deleted?: boolean
	deletedAt?: string | null
	_id: string
	discount: number
	shopId: string
	courier: {
		_id: string
		name: string
		image: string
	}
	price?: number
	cost: number
	productCost: number
	vatInfo?: {
		vat?: number
		vatType?: string
		isSelect?: boolean
	}
	weight?: number
	reference?: string
	status?: string
	createdAt: string
	updatedAt?: string
	products?: Array<IProductInOrderDetail>
	isDeliveryFailed?: boolean
	reasonDeliveryFailed?: {
		status: string
		reason: string
	}
	condition?: {
		expediteStatus?: ORDER_EXPEDITE
		isFillInSenderInfo?: boolean
		isFillInCustomerInfo?: boolean
		isFillInPayment?: boolean
		isFillInShipmentAddress?: boolean
		isFillInOriginAddress?: boolean
	}
	isFillInCompleted?: boolean
	printLabelStatus?: string
	orderInformation?: string
	note?: string
	njvServiceType?: string
	jntServiceType?: string
	spxServiceType?: string
	destinationTime?: string
	pickupTime?: string
	trackingNumber?: string
	liveId?: string
	createdBy?: {
		name: string
	}
	pageId?: string
	inboxId?: string
	socialMediaKeys?: string
	rejectTrackingNumber?: string
	boxSizeMeasuredFormStore?: {
		weight: number
		width: number
		height: number
		length: number
	}
	boxSize?: {
		width: number
		height: number
		length: number
	}
	merchantReturnFee?: number
	receiptNumber?:string
	channel?:string
	marketplaceOrder?:{
		marketplaceOrderId:string,
		marketplaceId:string;
	}
}
export interface IChatTabs {
	name: string
	value: string
}

export enum ORDER_EXPEDITE {
	NONE = 'NONE',
	WAITING = 'WAITING',
	COMPLETED = 'COMPLETED',
}
export interface InboxFacebookList{
	contactChannel:{
		name: string,
		image: string,
		imagePage: string,
		pageName:string,
		senderId: string,
		conversationId: string,
		lastUpdatedTime: string,
	},
	channel:string,
	pageId: string,
}
export interface FormFacebook{
	name: string,
	image: string,
	imagePage: string,
	pageName:string,
	channel:string,
	senderId: string,
	pageId: string,
	conversationId: string,
	lastUpdatedTime: string,
}

export enum CONSTANTS{
  INSUFFICIENT_CREDIT = 'INSUFFICIENT_CREDIT',
  ORDER_NOT_FOUND_OR_INVALID_STATUS = 'ORDER_NOT_FOUND_OR_INVALID_STATUS',
  ORDER_TRANSFER_LIMIT = 'ORDER_TRANSFER_LIMIT',
  CREDIT_TOP_UP_NOT_ENOUGH = 'CREDIT_TOP_UP_NOT_ENOUGH',
  ORDER_JNT_SERVICE_TYPE_NOT_FOUND = 'ORDER_JNT_SERVICE_TYPE_NOT_FOUND',
  ORDER_NJV_SERVICE_TYPE_NOT_FOUND = 'ORDER_NJV_SERVICE_TYPE_NOT_FOUND',
  SHOP_IS_NOT_KYC = 'SHOP_IS_NOT_KYC',
  SHOP_IS_VERIFY = 'SHOP_IS_VERIFY',
  UNIT_OF_PRODUCT_NOT_ENOUGH = 'UNIT_OF_PRODUCT_NOT_ENOUGH',
  ORDER_STATUS_CAN_NOT_UPDATE = 'ORDER_STATUS_CAN_NOT_UPDATE',
  COURIER_NOT_SUPPORT = 'COURIER_NOT_SUPPORT',
  COURIER_CHANNEL_EXISTING = 'COURIER_CHANNEL_EXISTING',
  ADDRESS_INVALID = 'ADDRESS_INVALID',
  COURIER_AREA_NOT_SUPPORT = 'COURIER_AREA_NOT_SUPPORT',
  ADDRESS_NOT_SUPPORT = 'ADDRESS_NOT_SUPPORT',
  DUPLICATE_PRODUCT_SKU = 'DUPLICATE_PRODUCT_SKU',
  ORDER_ERROR_INTEGRATE_WITH_COURIER = 'ORDER_ERROR_INTEGRATE_WITH_COURIER',
  BOOKING_ERROR_INTEGRATE_WITH_COURIER = 'BOOKING_ERROR_INTEGRATE_WITH_COURIER',
  ORDER_CAN_NOT_CANCEL = 'ORDER_CAN_NOT_CANCEL',
  ORDER_CAN_NOT_EXPEDITE = 'ORDER_CAN_NOT_EXPEDITE',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  USER_IS_EXISTING = 'USER_IS_EXISTING',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  SHOP_NOT_FOUND = 'SHOP_NOT_FOUND',
  RECONCILE_NOT_FOUND = 'RECONCILE_NOT_FOUND',
  ROLE_NOT_FOUND = 'ROLE_NOT_FOUND',
  BOOKING_CAN_NOT_UPDATE = 'BOOKING_CAN_NOT_UPDATE',
  BOOKING_NOT_FOUND = 'BOOKING_NOT_FOUND',
  BOOKING_LIMIT = 'BOOKING_LIMIT',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  CLAIM_NOT_FOUND = 'CLAIM_NOT_FOUND',
  ORDER_CAN_NOT_FILL_IN = 'ORDER_CAN_NOT_FILL_IN',
  RATE_PROMOTION_NOT_FOUND = 'RATE_PROMOTION_NOT_FOUND',
  INBOX_NOT_FOUND = 'INBOX_NOT_FOUND',
  TRANSACTION_APPROVED = 'TRANSACTION_APPROVED',
  TRANSACTION_STATUS_CAN_NOT_UPDATE = 'TRANSACTION_STATUS_CAN_NOT_UPDATE',
  TRANSACTION_NOT_FOUND = 'TRANSACTION_NOT_FOUND',
  BILL_PAYMENT_NOT_FOUND = 'BILL_PAYMENT_NOT_FOUND',
  BILL_PAYMENT_APPROVED = 'BILL_PAYMENT_APPROVED',
  BILL_PAYMENT_NOT_SAME_SHOP = 'BILL_PAYMENT_NOT_SAME_SHOP',
  TRANSACTION_THIRD_PARTY_ERROR = 'TRANSACTION_THIRD_PARTY_ERROR',
  THAILAND_POST_NOT_FOUND_TRACKING = 'THAILAND_POST_NOT_FOUND_TRACKING',
  OTP_NOT_FOUND = 'OTP_NOT_FOUND',
  OTP_PHONE_NO_INVALID = 'OTP_PHONE_NO_INVALID',
  OTP_INVALID = 'OTP_INVALID',
  OTP_REFERENCE_INVALID = 'OTP_REFERENCE_INVALID',
  OTP_EXPIRED = 'OTP_EXPIRED',
  OTP_TYPE_ERROR = 'OTP_TYPE_ERROR',
  SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR',
  SEND_MESSAGE_EXPIRED = 'SEND_MESSAGE_EXPIRED',
  UNABLE_NOTIFY_MESSAGES = 'UNABLE_NOTIFY_MESSAGES',
  DUPLICATE_PAGE = 'DUPLICATE_PAGE',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  TOP_UP_AMOUNT_NOT_FOUND = 'TOP_UP_AMOUNT_NOT_FOUND',
  PAYMENT_GATEWAY_CHANNEL_NOT_FOUND = 'PAYMENT_GATEWAY_CHANNEL_NOT_FOUND',
  PAGE_ACCESS_TOKEN_PERMISSION_INVALID = 'PAGE_ACCESS_TOKEN_PERMISSION_INVALID',
  PAGE_ERROR = 'PAGE_ERROR',
  CREDIT_TRANSACTION_NOT_FOUND = 'CREDIT_TRANSACTION_NOT_FOUND',
  ORDER_CAN_NOT_ADJUST_BILL_APPROVED = 'ORDER_CAN_NOT_ADJUST_BILL_APPROVED',
  ORDER_CAN_NOT_ADJUST_STATUS = 'ORDER_CAN_NOT_ADJUST_STATUS',
  ORDER_CAN_NOT_ADJUST_SHOP_CREDIT_TYPE = 'ORDER_CAN_NOT_ADJUST_SHOP_CREDIT_TYPE',
  REWARD_NOT_FOUND = 'REWARD_NOT_FOUND',
  REWARD_NOT_AVAILABLE = 'REWARD_NOT_AVAILABLE',
  INSUFFICIENT_POINTS = 'INSUFFICIENT_POINTS',
  REWARD_REDEEM_LIMIT_EXCEEDED = 'REWARD_REDEEM_LIMIT_EXCEEDED',
  POST_REPLY_NOT_FOUND = 'POST_REPLY_NOT_FOUND',
  NOT_FOUND_INVITE = 'NOT_FOUND_INVITE',
  INVITE_LINK_EXPIRED = 'INVITE_LINK_EXPIRED',
  VOUCHER_NOT_FOUND = 'VOUCHER_NOT_FOUND',
  VOUCHER_COMPLETED = 'VOUCHER_COMPLETED',
  CHANNEL_COURIER_NOT_SUPPORT = 'CHANNEL_COURIER_NOT_SUPPORT',
  CHANNEL_COURIER_PENDING = 'CHANNEL_COURIER_PENDING',
  CHANNEL_COURIER_IS_EXISTING = 'CHANNEL_COURIER_IS_EXISTING',
  ACCESS_DENIED = 'ACCESS_DENIED',
  NO_PERMISSIONS_TO_CREATE = 'NO_PERMISSIONS_TO_CREATE',
  PAYMENT_TOKEN_ERROR = 'PAYMENT_TOKEN_ERROR',
  SOME_SLIP_INVALID = 'SOME_SLIP_INVALID',
  CANNOT_EXTRACT_DATA = 'CANNOT_EXTRACT_DATA',
  BANK_ACCOUNT_NOT_FOUND = 'BANK_ACCOUNT_NOT_FOUND',
  NO_PERMISSIONS_TO_VERIFY_SLIP = 'NO_PERMISSIONS_TO_VERIFY_SLIP',
  MESSAGE_AND_IMAGE_REPLY_NOT_FOUND = 'MESSAGE_AND_IMAGE_REPLY_NOT_FOUND',
  BANK_TYPE_NOT_FOUND = 'BANK_TYPE_NOT_FOUND',
  ISSUE_ZONE_EXISTING = 'ISSUE_ZONE_EXISTING',
  PAGE_ACCESS_TOKEN_HAS_EXPIRED = 'PAGE_ACCESS_TOKEN_HAS_EXPIRED',
}
export interface CourierServiceInfoResponse {
	courier: string
	pickupInfo?: {
		pickupTime: number
		slots: {
			pickupTimeRangeId: number
			pickupTimeRange: number
		}[]
		date: string
		pickupTimeId:string,
		timeSlotLst:{
			timeSlot:{
				pickupTimeId:string,
			}[]
		}[]
	}[] | any[]
	dropoffInfo?:any[]
}
export enum SERVICE_TYPE {
	PICKUP = 'PICKUP',
  DROP_OFF = 'DROP_OFF',
}