
import { defineComponent, ref } from 'vue'
import { useWindowSize } from '@/use/useWindowSize'
import { useShippingStore } from '@/pinia/shipping/shipping.pinia'
import dayjs from 'dayjs'
export default defineComponent({
	name: 'DialogConfirmPaymentReceived',
	props: {
		isShowDialogConfirmPaymentReceived: {
			type: Boolean,
			default: false
		},
	},
	emits: [
		'update:isShowDialogConfirmPaymentReceived',
		'onConfirm',
		'onClose'
	],
	setup(_, { emit }) {
		const shippingStore = useShippingStore()
		const { mobileMode: isMobile } = useWindowSize()
		const selectedDate = ref(dayjs().format('YYYY-MM-DD')) 
		const handleClose = () => {
			selectedDate.value = dayjs().format('YYYY-MM-DD')
			emit('onClose')
		}

		const onConfirm = () => {
			shippingStore.updatePaymentReceiptDate(selectedDate.value)
			emit('onConfirm')
			handleClose()
		}

		const validateDate = () => {
			if (!selectedDate.value) {
				selectedDate.value = dayjs().format('YYYY-MM-DD')
			}
		}

		return {
			onConfirm,
			handleClose,
			validateDate,
			selectedDate,
			isMobile
		}
	},
})
