
import { defineComponent, PropType } from 'vue'
import { useWindowSize } from '@/use/useWindowSize'
import { IOrderUnNotifyList,IOrderErrorList } from '@/type/order/index'
import { ElNotification } from 'element-plus'
import { useAnalytics } from '@/use/useAnalytics'
import { EVENT_NAME } from '@/constants/analytics'
import { CONSTANTS } from '@/type/order'
import router from '@/router'

export default defineComponent({
	name: 'DialogWarningStatusOrderUnNotify',
	props: {
		isWarning: {
			type: Boolean,
			default: false
		},
		lengthItem: {
			type: Number,
			default: 0
		},
		orderStatusTransferSuccess: {
			type: Number,
			default: 0
		},
		orderFailedStatusList: {
			type: Array as PropType<Array<IOrderErrorList>>,
			default: () => []
		},
		orderUnNotifyList: {
			type: Array as PropType<Array<IOrderUnNotifyList>>,
			default: () => []
		},
		orderResDesc:{
			type: String,
			default: ''
		}
	},
	emits: [
		'update:isWarning',
	],
	setup(_, { emit }) {
		const { mobileMode: isMobile } = useWindowSize()
		const { logEvent } = useAnalytics()

		const handleClose = () => {
			emit('update:isWarning', false)
		}
		const onClickInbox = (pageId: string) => {
			return `https://business.facebook.com/latest/inbox/messenger?asset_id=${pageId}`
		}
		const onCopySuccess = (reference: string) => {
			ElNotification({
				title: 'สำเร็จ',
				message: 'คัดลอกลิงก์สรุปยอดหมายเลข '+reference+' สำเร็จ',
				type: 'success'
			})
			// logs
			logEvent({ eventName: EVENT_NAME.onClickCopyTextInDialogErrorUnableSendMessage })
		}
		const reduceTextCreatedBy = (name: string) => {
			if (name?.length > 15) {
				return `${name.slice(0, 15)}...`
			}
			return name
		}
		const onSeeChatInbox = () => {
			// logs
			logEvent({ eventName: EVENT_NAME.onClickSeeChatFanPageInDialogErrorUnableSendMessage })
		}
		const toConnectPage = () => {
			const win = window.open('/chat', '_blank')
			win?.focus()
		}
		const getErrorMessage = (orderResDesc: string): string => {
			if (orderResDesc === CONSTANTS.UNABLE_NOTIFY_MESSAGES) {
				return 'เนื่องจากส่งข้อความเกินระยะเวลากำหนด 24 ชม.<br> ข้อตกลงกำหนด Facebook'
			} else if (orderResDesc === CONSTANTS.PAGE_ACCESS_TOKEN_HAS_EXPIRED || orderResDesc === CONSTANTS.PAGE_ACCESS_TOKEN_PERMISSION_INVALID) {
				return 'เนื่องจากการเชื่อมต่อเพจหมดอายุ  <br>กรุณาทำการเชื่อมต่อเพจ Facebook ใหม่อีกครั้ง'
			}
			return 'เกิดข้อผิดพลาดกรุณา ติดต่อแอดมิน'
		}
		return {
			handleClose,
			onClickInbox,
			onCopySuccess,
			reduceTextCreatedBy,
			onSeeChatInbox,
			toConnectPage,
			isMobile,
			CONSTANTS,
			getErrorMessage
		}
	},
})
