
import { defineComponent, PropType } from 'vue'
import { useWindowSize } from '@/use/useWindowSize'
import { IOrderErrorList } from '@/type/order/index'

export default defineComponent({
	name: 'DialogWarningChangeStatus',
	props: {
		isWarning: {
			type: Boolean,
			default: false
		},
		lengthItem: {
			type: Number,
			default: 0
		},
		orderErrorList: {
			type: Array as PropType<Array<IOrderErrorList>>,
			default: () => []
		}
	},
	emits: [
		'update:isWarning',
	],
	setup(_, { emit }) {
		const { mobileMode: isMobile } = useWindowSize()

		const handleClose = () => {
			emit('update:isWarning', false)
		}

		return {
			handleClose,
			isMobile
		}
	},
})
